import { Typography } from "@mui/material";
import Banner from "../components/common/Banner";

const AboutUs = () => {
  return (
    <div>
      <Banner
        title="About Us"
      />
    </div>
  )
};

export default AboutUs;
