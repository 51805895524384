import { Typography } from "@mui/material";
import Banner from "../components/common/Banner";

const InsulatedBottle = () => {
  return (
    <div>
        <Banner
        title="Insulated Bottle"
      />
    </div>
  )
};

export default InsulatedBottle;
