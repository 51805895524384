import { Typography } from "@mui/material";
import Banner from "../components/common/Banner";

const Product = () => {
  return (
    <div>
      <Banner
        title="Product"
      />
    </div>
  )
};

export default Product;
