import Banner from "../components/common/Banner";
import Contact from "../components/contactus/Contact";

const ContactUs = () => {
  return (
    <div>
     <Banner
        title="Contact Us"
      />
      <Contact/>
    </div>
  )
};

export default ContactUs;
